<template>
  <SpFinder
    :id="id"
    ref="finder"
    :url="{
      setting: dbTableSetting,
      data: dbTableList
    }"
    title="数据表配置"
    :actions="actions"
    :custom-params="customParams"
  />
</template>

<script>
import { dbTableList, dbTableSetting, synchronizationTable } from '@/api/finder';

export default {

  data () {
    const id = this.$route.query.id;
    return {
      id,
      dbTableList,
      dbTableSetting,
      detailType: 'edit',
      customParams: {

      },
      actions: [
        {
          'name': '导入表',
          'key': 'edit',
          'slot': 'header',
          'buttonIcon': 'el-icon-upload',
          'type': 'button',
          'buttonType': 'default',
          action: {
            type: 'dialog',
            title: '导入表',
            component: 'views/db_table/exportTable.vue'
          }
        },
        {
          name: '创建表',
          key: 'create',
          slot: 'header',
          type: 'button',
          buttonType: 'primary',
          action: {
            type: 'dialog',
            title: '创建一条记录',
            component: 'views/db_table/detail.vue',
            handler: function () {
              this.detailType = 'add';
            }.bind(this)
          }
        },
        {
          key: 'view',
          name: '查看数据表字段',
          type: 'button',
          action: {
            path: '/db_table/field',
            type: 'link',
          }
        },
        {
          key: 'edit',
          name: '编辑22',
          type: 'button',
          action: {
            type: 'dialog',
            title: '编辑当前数据表',
            component: () => import('./detail.vue')
          }
        },
        {
          key: 'sys',
          name: '同步',
          type: 'button',
          action: {
            type: null,
            handler: function (rows) {
              this.handleSynchronizationTable(rows);
            }.bind(this)
          }
        }
      ]
    };
  },
  methods: {
    handleSynchronizationTable (rows) {
      this.$confirm('请确认当前操作', '提示', {
        type: 'warning'
      }).then(async () => {
        let params = {
          id: rows.map(r => r.id).join(','),
          connectionId: this.$route.query.id,
          connectionTableName: rows.map(r => r.connectionTableName).join(',')
        };
        await this.$http.post(synchronizationTable, params);
        this.$refs.finder.refresh(true);
      });
    }
  }
};
</script>
